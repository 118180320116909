import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"
import get from "lodash/get"

import RoomMock from "../components/RoomMock"
import MediaThumbnail from "../components/MediaThumbnail"

import { useModal } from "../hooks/modal"

const VideoList = styled.ul`
  list-style: none;
  margin: 0 auto;

  @media (orientation: portrait), (max-width: 719px), (max-height: 429px) {
    max-width: 30.625rem;
    padding: 0 2.5rem calc(12% + 1rem);
    overflow: hidden;
  }

  @media (orientation: landscape) and (min-width: 720px) and (min-height: 430px) {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 62.5rem;
    padding: 0 2%;
    transform: translate(-50%, -50%);
  }
`

const VideoListItem = styled.li`
  max-width: 12.5rem;

  @media (orientation: portrait), (max-width: 719px), (max-height: 429px) {
    width: 48.8%;

    margin-bottom: -12%;
    clear: right;

    &:nth-child(even) {
      float: right;
    }
  }

  @media (orientation: landscape) and (min-width: 720px) and (min-height: 430px) {
    width: 25%;
    min-width: 9rem;
    margin: 0 2%;

    &:nth-child(odd) {
      margin-top: -5%;
    }

    &:nth-child(even) {
      margin-bottom: -5%;
    }
  }
`

export default function RoomTemplate2({
  data: { videosYaml, ciceronYaml },
  pageContext: { lang, pagePath, translations },
}) {
  const intl = useIntl()
  const { openModal } = useModal()

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={2}
      title={intl.formatMessage({ id: "roomHeading2" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning4_Tecknat_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      <VideoList>
        {(videosYaml.videos || []).map((video) => (
          <VideoListItem key={video.id}>
            <MediaThumbnail
              title={video.title}
              background={video.image}
              isRound
              border
              onClick={(e) => openModal(e, "video", { lang, id: video.id, title: video.title })}
              imageTransform
            />
          </VideoListItem>
        ))}
      </VideoList>
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    videosYaml(lang: { eq: $lang }, page: { eq: "Tecknat" }) {
      videos {
        id
        provider
        title
        image
        audio_description
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-2" }) {
      text
      sound
    }
  }
`
